<form [formGroup]="notificationFormGroup">
  <div>
    <span>
      <h5>{{ headings.activationRequest }}</h5>
    </span>
  </div>
  <div class="form-group input-group">
    <div class="col-md-12">
      <kendo-floatinglabel class="k-input-full" text="{{ labels.name }}">
        <input kendoTextBox formControlName="name" class="k-input-full" [value]="notification.name" />
      </kendo-floatinglabel>
    </div>
  </div>
  <div class="form-group input-group">
    <div class="col-md-12">
      <kendo-floatinglabel class="k-input-full" text="{{ labels.telephoneNumber }}">
        <input
          kendoTextBox
          formControlName="telephoneNumber"
          class="k-input-full"
          [value]="notification.telephoneNumber" />
      </kendo-floatinglabel>
    </div>
  </div>
  <div class="form-group input-group">
    <div class="col-md-12">
      <kendo-floatinglabel class="k-input-full" text="{{ labels.portfolio }}">
        <input kendoTextBox formControlName="portfolio" class="k-input-full" [value]="notification.portfolio" />
      </kendo-floatinglabel>
    </div>
  </div>
  <div>
    <span>
      <h5>{{ headings.details }}</h5>
    </span>
  </div>
  <div class="form-group input-group">
    <div class="{{ currentInstrumentCssClass }}">
      <kendo-floatinglabel class="k-input-full" text="{{ labels.currentInstrument }}">
        <input
          kendoTextBox
          formControlName="currentInstrument"
          class="k-input-full"
          [value]="notification.currentInstrument.instrument" />
      </kendo-floatinglabel>
    </div>
    <div *ngIf="showSubsequentInstrument" class="col-md-6 col-sm-6 col-6">
      <kendo-floatinglabel class="k-input-full" text="{{ labels.subsequentInstrument }}">
        <input
          kendoTextBox
          formControlName="subsequentInstrument"
          class="k-input-full"
          [value]="notification.subsequentInstrument.instrument" />
      </kendo-floatinglabel>
    </div>
  </div>
  <div class="form-group input-group">
    <div class="{{ currentInstrumentCssClass }}">
      <kendo-floatinglabel class="k-input-full" text="{{ labels.requestedQuantity }}">
        <input
          kendoTextBox
          formControlName="currentRequestedQuantity"
          class="k-input-full"
          [value]="notification.currentInstrument.requestedQuantity?.toString()" />
      </kendo-floatinglabel>
    </div>
    <div *ngIf="showSubsequentInstrument" class="col-md-6 col-sm-6 col-6">
      <kendo-floatinglabel class="k-input-full" text="{{ labels.requestedQuantity }}">
        <input
          kendoTextBox
          formControlName="subsequentRequestedQuantity"
          class="k-input-full"
          [value]="notification.subsequentInstrument.requestedQuantity?.toString()" />
      </kendo-floatinglabel>
    </div>
  </div>
  <div class="form-group input-group">
    <div class="{{ currentInstrumentCssClass }}">
      <kendo-floatinglabel class="k-input-full" text="{{ labels.earlyActivationRequested }}">
        <input
          type="checkbox"
          kendoCheckBox
          formControlName="currentEarlyActivationRequested"
          [value]="notification.currentInstrument.earlyActivationRequested" />
      </kendo-floatinglabel>
    </div>
    <div *ngIf="showSubsequentInstrument" class="col-md-6 col-sm-6 col-6">
      <kendo-floatinglabel class="k-input-full" text="{{ labels.earlyActivationRequested }}">
        <kendo-label text="">
          <input type="hidden" formControlName="subsequentEarlyActivationRequested" class="k-input-full" [value]="" />
        </kendo-label>
      </kendo-floatinglabel>
    </div>
  </div>
  <div class="form-group input-group">
    <div class="{{ currentInstrumentCssClass }}">
      <kendo-floatinglabel class="k-input-full" text="{{ labels.requestActivationTime }}">
        <kendo-datetimepicker
          formControlName="currentRequestedActivationTime"
          [format]="dateTimeFormat"
          class="k-input-full"
          [value]="notification.currentInstrument.requestedActivationTime"></kendo-datetimepicker>
      </kendo-floatinglabel>
    </div>
    <div *ngIf="showSubsequentInstrument" class="col-md-6 col-sm-6 col-6">
      <kendo-floatinglabel class="k-input-full" text="{{ labels.requestActivationTime }}">
        <kendo-datetimepicker
          formControlName="subsequentRequestedActivationTime"
          [format]="dateTimeFormat"
          class="k-input-full"
          [value]="notification.subsequentInstrument.requestedActivationTime"></kendo-datetimepicker>
      </kendo-floatinglabel>
    </div>
  </div>
  <!-- <ng-container *ngIf="mustAcknowledgeMessageRead">
    <div class="form-group input-group text-right">
      <div class="col-md-12">
        <nexweb-acknowledge-message-read
          formControlName="acknowledgeMessageRead"
        ></nexweb-acknowledge-message-read>
      </div>
    </div>
  </ng-container> -->
</form>
