import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BidNotificationInfo, BmDataAccessFacade } from '@nexweb/shared/data-access/bm';

import { RespondToActivationRequest } from '../../../models';
import { RespondToActivationRequestService } from '../../../services';

@Component({
  selector: 'nexweb-declination',
  templateUrl: './declination.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeclinationComponent implements OnInit {
  @Input() bidId: number;

  notification$: Observable<RespondToActivationRequest.Notification>;
  labels: RespondToActivationRequest.NotificationLabels;
  dateTimeFormat: string;
  heading: string;

  constructor(
    private respondToActivationRequestService: RespondToActivationRequestService,
    private bmFacade: BmDataAccessFacade,
  ) {}

  ngOnInit(): void {
    this.labels = this.respondToActivationRequestService.getDeclinationLabels();
    this.dateTimeFormat = this.respondToActivationRequestService.getDateTimeFormat();
    this.heading = this.respondToActivationRequestService.getDeclinationHeading();

    this.initialiseData();
  }

  private initialiseData(): void {
    this.notification$ = this.bmFacade.getBidNotificationInfo(this.bidId).pipe(
      map((bidNotificationInfo: BidNotificationInfo) => {
        const bidNotificationData = {
          instrument: bidNotificationInfo.instrument,
          activatingPortfolio: bidNotificationInfo.requestingTsoPortfolioName,
          supplyingBspPortfolio: bidNotificationInfo.supplyingBspPortfolioName,
          activatedQuantity: bidNotificationInfo.requestedQuantity,
          timeRequestWasDeclined: bidNotificationInfo.rejectTime,
          reasonForDeclining: bidNotificationInfo.rejectReason,
        } as RespondToActivationRequest.Notification;

        return bidNotificationData;
      }),
    );
  }
}
