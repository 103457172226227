import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { conditionalValidator } from '@nexweb/util';

import { RequestActivation } from '../../../models';

@Component({
  selector: 'nexweb-notification',
  templateUrl: './notification.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationComponent implements OnInit {
  @Input() notification: RequestActivation.ActivationRequestNotification;
  @Input() headings: RequestActivation.NotificationHeadings;
  @Input() labels: RequestActivation.NotificationLabels;
  @Input() dateTimeFormat: string;
  @Input() mustAcknowledgeMessageRead: boolean;

  notificationFormGroup: FormGroup;

  constructor() {}

  get showSubsequentInstrument() {
    return !!this.notification.subsequentInstrument.instrument;
  }

  get currentInstrumentCssClass() {
    if (this.showSubsequentInstrument) {
      return 'col-md-6 col-sm-6';
    } else {
      return 'col-md-12 col-sm-12';
    }
  }

  ngOnInit(): void {
    this.initialise();
  }

  public initialise(): void {
    this.notificationFormGroup = new FormGroup({
      name: new FormControl({ value: this.notification.name, disabled: true }),
      telephoneNumber: new FormControl({
        value: this.notification.telephoneNumber ?? ' ',
        disabled: true,
      }),
      portfolio: new FormControl({
        value: this.notification.portfolio,
        disabled: true,
      }),
      currentInstrument: new FormControl({
        value: this.notification.currentInstrument.instrument,
        disabled: true,
      }),
      currentRequestedQuantity: new FormControl({
        value: this.notification.currentInstrument.requestedQuantity,
        disabled: true,
      }),
      currentEarlyActivationRequested: new FormControl({
        value: this.notification.currentInstrument.earlyActivationRequested,
        disabled: true,
      }),
      currentRequestedActivationTime: new FormControl({
        value: this.notification.currentInstrument.requestedActivationTime,
        disabled: true,
      }),
      subsequentInstrument: new FormControl({
        value: this.notification.subsequentInstrument.instrument,
        disabled: true,
      }),
      subsequentRequestedQuantity: new FormControl({
        value: this.notification.subsequentInstrument.requestedQuantity,
        disabled: true,
      }),
      subsequentEarlyActivationRequested: new FormControl({
        value: ' ',
        disabled: true,
      }),
      subsequentRequestedActivationTime: new FormControl({
        value: this.notification.subsequentInstrument.requestedActivationTime,
        disabled: true,
      }),
      acknowledgeMessageRead: new FormControl(
        {
          value: false,
          disabled: false,
        },
        [conditionalValidator(() => this.mustAcknowledgeMessageRead, Validators.required)],
      ),
    });
  }
}
