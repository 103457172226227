import { Injectable } from '@angular/core';

import { ConfigStateService } from '@abp/ng.core';
import { LocalizationService } from '@abp/ng.core';

import { RequestActivation } from '../models';

@Injectable({
  providedIn: 'root',
})
export class RequestActivationService {
  constructor(
    private localizationService: LocalizationService,
    private configStateService: ConfigStateService,
  ) {}

  public getNotificationLabels(): RequestActivation.NotificationLabels {
    return {
      name: this.localizationService.instant({
        key: 'Bm::LabelRequestingTsoName',
        defaultValue: 'Requesting TSO Name',
      }),
      telephoneNumber: this.localizationService.instant({
        key: 'Bm::LabelRequestingTsoTelNum',
        defaultValue: 'Requesting TSO Telephone no',
      }),

      portfolio: this.localizationService.instant({
        key: 'Bm::LabelRequestingBspPortfolio',
        defaultValue: 'Requesting TSO Portfolio',
      }),

      currentInstrument: this.localizationService.instant({
        key: 'Bm::LabelCurrentInstrument',
        defaultValue: 'Current Instrument',
      }),

      requestedQuantity: this.localizationService.instant({
        key: 'Bm::LabelRequestedQuantity',
        defaultValue: 'Requested Quantity (MW)',
      }),

      earlyActivationRequested: this.localizationService.instant({
        key: 'Bm::LabelEarlyActivationRequested',
        defaultValue: 'Early Activation Requested',
      }),

      requestActivationTime: this.localizationService.instant({
        key: 'Bm:RequestActivationLabelRequestedActivationTime',
        defaultValue: 'Requested Activation Time',
      }),

      subsequentInstrument: this.localizationService.instant({
        key: 'Bm::LabelSubsequentInstrument',
        defaultValue: 'Subsequent Instrument',
      }),
    } as RequestActivation.NotificationLabels;
  }

  public getNotificationHeadings(): RequestActivation.NotificationHeadings {
    return {
      activationRequest: this.localizationService.instant({
        key: 'Bm:RequestActivationHeadingReceived',
        defaultValue: 'An Activation Request has been received from',
      }),
      details: this.localizationService.instant({
        key: 'Bm::RequestActivationHeadingDetails',
        defaultValue: 'Details of the Activation Request are',
      }),
    } as RequestActivation.NotificationHeadings;
  }

  public getConfirmationLabels(): RequestActivation.NotificationLabels {
    return {
      name: this.localizationService.instant({
        key: 'Bm::LabelSupplyingTsoName',
        defaultValue: 'Supplying TSO Name',
      }),
      telephoneNumber: this.localizationService.instant({
        key: 'Bm::LabelSupplyingTsoTelNum',
        defaultValue: 'Supplying TSO Telephone no',
      }),

      portfolio: this.localizationService.instant({
        key: 'Bm::LabelSupplyingBspPortfolio',
        defaultValue: 'BSP Portfolio',
      }),

      currentInstrument: this.localizationService.instant({
        key: 'Bm::LabelCurrentInstrument',
        defaultValue: 'Current Instrument',
      }),

      requestedQuantity: this.localizationService.instant({
        key: 'Bm::LabelRequestedQuantity',
        defaultValue: 'Requested Quantity (MW)',
      }),

      earlyActivationRequested: this.localizationService.instant({
        key: 'Bm::LabelEarlyActivationRequested',
        defaultValue: 'Early Activation Requested',
      }),

      requestActivationTime: this.localizationService.instant({
        key: 'Bm::LabelRequestedFullDispatchTime',
        defaultValue: 'Requested Full Dispatch Time',
      }),

      subsequentInstrument: this.localizationService.instant({
        key: 'Bm::LabelSubsequentInstrument',
        defaultValue: 'Subsequent Instrument',
      }),
    } as RequestActivation.NotificationLabels;
  }

  public getConfirmationHeadings(): RequestActivation.NotificationHeadings {
    return {
      activationRequest: this.localizationService.instant({
        key: 'Bm::RequestActivationHeadingSubmitted',
        defaultValue: 'Your Activation Request has been Submitted to',
      }),
      details: this.localizationService.instant({
        key: 'Bm::RequestActivationHeadingDetails',
        defaultValue: 'Details of the Activation Request are',
      }),
    } as RequestActivation.NotificationHeadings;
  }

  public getDateTimeFormat() {
    return this.configStateService.getSetting('NexBase.DateTimeFormat');
  }
}
