import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CountdownModule } from 'ngx-countdown';

import { UI_COMPONENTS } from './components';
import { FloatingLabelModule, LabelModule } from '@progress/kendo-angular-label';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ButtonsModule } from '@progress/kendo-angular-buttons';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    CountdownModule,
    FloatingLabelModule,
    InputsModule,
    DateInputsModule,
    LabelModule,
    ButtonsModule,
    LayoutModule,
  ],
  declarations: [...UI_COMPONENTS],
  exports: [...UI_COMPONENTS],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedBmSystemMessagingComponentsModule {
  static forRoot(): ModuleWithProviders<SharedBmSystemMessagingComponentsModule> {
    return {
      ngModule: SharedBmSystemMessagingComponentsModule,
    };
  }
}
