import { Injectable } from '@angular/core';

import { ConfigStateService, LocalizationService } from '@abp/ng.core';

import { RequestExtensionOfActivationPeriod } from '../models';

@Injectable({
  providedIn: 'root',
})
export class RequestExtensionOfActivationPeriodService {
  constructor(
    private localizationService: LocalizationService,
    private configStateService: ConfigStateService,
  ) {}

  public getNotificationLabels(): RequestExtensionOfActivationPeriod.Labels {
    return {
      name: this.localizationService.instant({
        key: 'Bm::LabelRequestingTsoName',
        defaultValue: 'Requesting TSO Name',
      }),
      telephoneNumber: this.localizationService.instant({
        key: 'Bm::LabelRequestingTsoTelNum',
        defaultValue: 'Requesting TSO Telephone no',
      }),
      portfolio: this.localizationService.instant({
        key: 'Bm::LabelRequestingTsoPortfolio',
        defaultValue: 'Requesting TSO Portfolio',
      }),
      quantity: this.localizationService.instant({
        key: 'Bm::LabelRequestedQuantity',
        defaultValue: 'Requested Quantity (MW)',
      }),
      instrument: this.localizationService.instant({
        key: 'Bm::LableInstrument',
        defaultValue: 'Instrument',
      }),
      requestedActivationTime: this.localizationService.instant({
        key: 'Bm::LabelRequestedActivationTime',
        defaultValue: 'Requested Activation Time',
      }),
    } as RequestExtensionOfActivationPeriod.Labels;
  }

  public getNotificationHeadings(): RequestExtensionOfActivationPeriod.Headings {
    return {
      activationExtension: this.localizationService.instant({
        key: 'Bm::ExtensionRequestHeadingNotification',
        defaultValue: 'An Activation Extension Request has been issued by the following TSO',
      }),
      details: this.localizationService.instant({
        key: 'Bm::ExtensionRequestHeadingDetails',
        defaultValue: 'Details of the Activation Extension Request',
      }),
    } as RequestExtensionOfActivationPeriod.Headings;
  }

  public getConfirmationLabels(): RequestExtensionOfActivationPeriod.Labels {
    return {
      name: this.localizationService.instant({
        key: 'Bm::LabelSupplyingTsoName',
        defaultValue: 'Supplying TSO Name',
      }),
      telephoneNumber: this.localizationService.instant({
        key: 'Bm::LabelSupplyingTsoTelNum',
        defaultValue: 'Supplying TSO Telephone no',
      }),
      portfolio: this.localizationService.instant({
        key: 'Bm::LabelSupplyingBspPortfolio',
        defaultValue: 'BSP Portfolio',
      }),
      quantity: this.localizationService.instant({
        key: 'Bm::LabelRequestedQuantity',
        defaultValue: 'Requested Quantity (MW)',
      }),
      instrument: this.localizationService.instant({
        key: 'Bm::LabelInstrument',
        defaultValue: 'Instrument',
      }),
      requestedActivationTime: this.localizationService.instant({
        key: 'Bm::LabelRequestedActivationTime',
        defaultValue: 'Requested Activation Time',
      }),
    } as RequestExtensionOfActivationPeriod.Labels;
  }

  public getConfirmationHeadings(): RequestExtensionOfActivationPeriod.Headings {
    return {
      activationExtension: this.localizationService.instant({
        key: 'Bm::ExtensionRequestHeadingConfirmation',
        defaultValue: 'An Activation Extension has been issued to the following TSO',
      }),
      details: this.localizationService.instant({
        key: 'Bm::ExtensionRequestHeadingDetails',
        defaultValue: 'Details of the Activation Extension Request',
      }),
    } as RequestExtensionOfActivationPeriod.Headings;
  }

  public getDateTimeFormat(): string {
    return this.configStateService.getSetting('NexBase.DateTimeFormat');
  }

  public getTsoResponseLabels(): RequestExtensionOfActivationPeriod.ExtensionResponseLabels {
    return {
      instrument: this.localizationService.instant({
        key: 'Bm::LabelInstrument',
        defaultValue: 'Instrument',
      }),
      requestingTsoPortfolio: this.localizationService.instant({
        key: 'Bm::LabelRequestingTsoPortfolio',
        defaultValue: 'Requesting TSO Portfolio',
      }),
      supplyingBspPortfolio: this.localizationService.instant({
        key: 'Bm::LabelSupplyingBspPortfolio',
        defaultValue: 'Supplying BSP Portfolio',
      }),
      requestedQuantity: this.localizationService.instant({
        key: 'Bm::LabelRequestedQuantity',
        defaultValue: 'Requested Quantity (MW)',
      }),
      defaultFullDispatchTime: this.localizationService.instant({
        key: 'Bm::LabelDefaultFullDispatchTime',
        defaultValue: 'Default Full Dispatch Time',
      }),
      confirmedFullDispatchTime: this.localizationService.instant({
        key: 'Bm::LabelConfirmedFullDispatchTime',
        defaultValue: 'Confirmed Full Dispatch Time',
      }),
      activationRequestAccepted: this.localizationService.instant({
        key: 'Bm::LabelActivationRequestAccepted',
        defaultValue: 'Activation Request Accepted',
      }),
      reasonForDeclining: this.localizationService.instant({
        key: 'Bm::LabelReasonForDecliningActivationRequest',
        defaultValue: 'Reason for Declining the Activation Request',
      }),
    } as RequestExtensionOfActivationPeriod.ExtensionResponseLabels;
  }
}
