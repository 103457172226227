import { Component, Input, OnInit } from '@angular/core';

import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

import { BidNotificationInfo, BmDataAccessFacade } from '@nexweb/shared/data-access/bm';

import { RequestExtensionOfActivationPeriod } from '../../../models';
import { RequestExtensionOfActivationPeriodService } from '../../../services/request-extension-of-activation-period.service';

@Component({
  selector: 'nexweb-ext-req-tso-conf',
  templateUrl: './ext-req-tso-conf.component.html',
})
export class ExtReqTsoConfComponent implements OnInit {
  @Input() bidId: number;

  extension$: Observable<RequestExtensionOfActivationPeriod.ActivationExtension>;
  dateTimeFormat: string;
  labels: RequestExtensionOfActivationPeriod.Labels;
  headings: RequestExtensionOfActivationPeriod.Headings;

  constructor(
    private bmFacade: BmDataAccessFacade,
    private requestExtensionOfActivationService: RequestExtensionOfActivationPeriodService,
  ) {}

  ngOnInit(): void {
    this.labels = this.requestExtensionOfActivationService.getConfirmationLabels();
    this.headings = this.requestExtensionOfActivationService.getConfirmationHeadings();
    this.dateTimeFormat = this.requestExtensionOfActivationService.getDateTimeFormat();

    this.initialiseData();
  }

  private initialiseData(): void {
    this.extension$ = this.bmFacade.getBidNotificationInfo(this.bidId).pipe(
      map((bidNotificationInfo: BidNotificationInfo) => {
        const bidNotificationData = {
          name: bidNotificationInfo.supplyingTsoName,
          telephoneNumber: bidNotificationInfo.supplyingTsoPhoneNumber,
          portfolio: bidNotificationInfo.supplyingBspPortfolioName,
          requestedQuantity: bidNotificationInfo.requestedQuantity,
          instrument: bidNotificationInfo.instrument,
          requestedActivationTime: bidNotificationInfo.requestFullDispatchTime,
        } as RequestExtensionOfActivationPeriod.ActivationExtension;

        return bidNotificationData;
      }),
    );
  }
}
